<template>
  <div class="palabras-container">
    <PalabrasList />
  </div>
</template>
<script>
  import { useStore } from 'vuex'
  import { computed, onBeforeMount, watch, ref } from 'vue'
  import { selectProjectContract } from '@/services/ProjectService'
  import PalabrasList from '@/components/projects/palabras/palabras-list/'
  export default {
    name: 'Palabras',
    components: { PalabrasList },
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user },
      } = store
      const addressState = computed(() => user.walletAddress)
      const web3 = computed(() => contractState.web3)

      const addressStateRef = ref(addressState)

      onBeforeMount(async () => {
        if (web3.value) {
          await selectProjectContract('palabras', web3.value, dispatch)
        }
      })
      watch([web3], async () => {
        await selectProjectContract('palabras', web3.value, dispatch)
      })
      return { addressStateRef }
    },
  }
</script>
<style scoped>
  .palabras {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
