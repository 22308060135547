import { db } from '@/firebase'
import { PALABRAS_COLLECTION } from '@/constants/collections'
import axios from 'axios'
const palabrasRef = db.collection(PALABRAS_COLLECTION)

export async function getRoundSignedPalabras(roundNumber, palabraType) {
  try {
    const snapshot = await palabrasRef
      .where('palabraType', '==', palabraType)
      .where('roundNumber', '==', roundNumber.toString())
      .get()
    const { docs } = snapshot

    return docs.map((doc) => {
      const { id } = doc
      return { id, ...doc.data() }
    })
  } catch (error) {
    console.error('Error getting words by current round.')
    throw error
  }
}

export async function getPalabrasAllowListSignature(
  walletAddress,
  signedPalabra
) {
  const params = {
    walletAddress: walletAddress,
    roundNumber: signedPalabra.roundNumber,
    word: signedPalabra.word,
  }

  const signatureResponse = await axios.get(
    process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL +
      '/api/getAllowListPalabraSignature',
    { params }
  )

  return signatureResponse
}
