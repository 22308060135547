<template>
  <el-row>
    <el-col class="palabras-border-wrapper" style="margin: 0px 0px 0px 0px">
      <el-card shadow="always" :class="itemCardClass()">
        <el-row :gutter="20">
          <el-col style="white-space: nowrap" :span="9">
            <span class="gold-text subtitle">
              {{ signedPalabra.word }}
            </span>
            <el-tooltip
              class="box-item"
              effect="dark"
              placement="top-start"
              v-if="
                palabraFrases.length > 0 &&
                (signedPalabra.roundNumber == currentRound ||
                  signedPalabra.roundNumber == 0)
              "
            >
              <template #content>
                <span v-html="starTooltipContent()"></span>
              </template>
              <img
                :src="require('@/assets/icons/star-icon.svg')"
                class="palabra-badge"
              />
            </el-tooltip>
            <el-tooltip
              class="box-item"
              effect="dark"
              placement="top-start"
              v-if="signedPalabra.palabraType == 'claimMint'"
            >
              <template #content>
                <span v-html="claimTooltipContent()"></span>
              </template>
              <a :href="signedPalabra.collectionLink" target="_blank">
                <img
                  :src="require('@/assets/icons/collection-icon.svg')"
                  class="claim-palabra-badge"
                />
              </a>
            </el-tooltip>
            <div class="small-word">
              {{ msgToAvailableMint() }}
            </div>
          </el-col>
          <el-col :span="15">
            <div class="mintBtnDiv">
              <el-select
                v-if="
                  signedPalabra.palabraType == 'publicMint' &&
                  availableMints() > 0 &&
                  signedPalabra.roundNumber == currentRound
                "
                size="small"
                placeholder="1"
                :disabled="!allowToMint()"
                style="margin: 3px 6px 0px 0px; width: 25%"
                v-model="quantityToMint"
              >
                <el-option
                  v-for="(o, index) in availableMints()"
                  :key="index"
                  :label="index + 1"
                  :value="index + 1"
                />
              </el-select>
              <el-button
                class="mint-button"
                v-if="
                  signedPalabra.palabraType == 'publicMint' &&
                  signedPalabra.roundNumber == currentRound
                "
                :disabled="!allowToMint()"
                type="primary"
                @click="doMinting()"
              >
                Mint ({{ mintPrice * quantityToMint }} eth)
              </el-button>
              <el-button
                class="mint-button"
                v-if="
                  signedPalabra.palabraType == 'premiumMint' &&
                  (signedPalabra.roundNumber == currentRound ||
                    signedPalabra.roundNumber == 0)
                "
                type="primary"
                :disabled="!allowToPremiumMint()"
                @click="doPremiumMinting()"
              >
                Mint ({{ premiumPriceToEth }} eth)
              </el-button>
              <el-button
                class="mint-button"
                v-if="
                  signedPalabra.palabraType == 'claimMint' &&
                  signedPalabra.roundNumber == currentRound
                "
                :disabled="!allowToClaim()"
                type="primary"
                @click="doClaiming()"
              >
                Claim (free mint)
              </el-button>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
  import { computed, ref, onBeforeMount, watch } from 'vue'
  import { useStore } from 'vuex'

  import PalabrasContractService from '@/services/PalabrasContractService'
  import { getPalabrasAllowListSignature } from '@/services/PalabrasService'
  import { selectNotification } from '@/utils/utils'

  export default {
    name: 'PalabrasListItem',
    components: {},
    props: [
      'signedPalabra',
      'currentRound',
      'mintPrice',
      'publicMinting',
      'allowListMinting',
      'isInAllowList',
      'contractPaused',
      'palabraFrases',
      'collectionsWalletTokens',
    ],
    emits: ['setMintingStep'],
    setup(props) {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, root },
      } = store

      const contract = computed(() => contractState.contract)
      const selectedProject = computed(() => root.selectedProject)
      const walletAddress = computed(() => user.walletAddress)
      const web3 = computed(() => contractState.web3)
      const claimableTokens = ref()
      const mintedCounts = ref(0)
      const walletTokens = ref()
      const premiumPriceToEth = ref(0)
      const premiumMintedWord = ref(true)
      const maxPublicMints = ref(0)
      const quantityToMint = ref(1)

      let palabrasContractService

      const loadContractData = async () => {
        palabrasContractService = new PalabrasContractService(
          web3.value,
          contract.value,
          true,
          store
        )
        if (
          props.signedPalabra.palabraType == 'publicMint' &&
          props.signedPalabra.roundNumber == props.currentRound
        ) {
          mintedCounts.value = await palabrasContractService.mintedCounts(
            props.signedPalabra
          )

          maxPublicMints.value = await palabrasContractService.maxPublicMints()
        }
        if (props.signedPalabra.palabraType == 'premiumMint') {
          premiumPriceToEth.value = web3.value.utils?.fromWei(
            props.signedPalabra.price,
            'ether'
          )
          premiumMintedWord.value = await palabrasContractService.mintedWord(
            props.signedPalabra
          )
        }
      }

      const loadWalletTokens = async () => {
        walletTokens.value = props.collectionsWalletTokens
          ?.filter(
            (t) =>
              t.address.toLowerCase() ==
              props.signedPalabra.collectionAddress.toLowerCase()
          )
          .map((t) => t.tokens)[0]
        claimableTokens.value =
          await palabrasContractService.getClaimableTokens(
            walletTokens.value,
            props.signedPalabra
          )
      }

      const allowToClaim = () =>
        leftToMint(props.signedPalabrasignedPalabra) > 0 &&
        props.signedPalabra.roundNumber == props.currentRound &&
        !props.contractPaused

      const allowToPremiumMint = () =>
        !premiumMintedWord.value && !props.contractPaused

      const allowToMint = () => {
        let allowListMint
        if (props.signedPalabra.palabraType == 'publicMint') {
          if (props.publicMinting) {
            allowListMint = true
          } else {
            if (props.allowListMinting) {
              allowListMint = props.isInAllowList
            } else {
              allowListMint = false
            }
          }
        } else {
          allowListMint = true
        }
        return (
          leftToMint() > 0 &&
          props.signedPalabra.roundNumber == props.currentRound &&
          allowListMint &&
          !props.contractPaused
        )
      }

      const leftToMint = () => {
        if (props.signedPalabra.palabraType == 'claimMint') {
          return (
            walletTokens.value?.length - claimableTokens.value?.totalClaimed
          )
        } else if (props.signedPalabra.palabraType == 'publicMint') {
          return (
            parseInt(props.signedPalabra.quantityAllowed) -
            parseInt(mintedCounts.value)
          )
        }
      }

      const availableMints = () => {
        return Math.min(leftToMint(), maxPublicMints.value)
      }

      const msgToAvailableMint = () => {
        if (props.signedPalabra.roundNumber != props.currentRound) {
          if (props.signedPalabra.palabraType == 'premiumMint') {
            if (props.signedPalabra.minted == 'true') {
              return '1 of 1 - Already minted'
            } else {
              return '1 of 1 - Available to mint'
            }
          } else {
            return ''
          }
        } else {
          if (props.signedPalabra.palabraType == 'premiumMint') {
            return '1 of 1 - Already minted'
          } else {
            const mintsLeft = leftToMint()
            if (mintsLeft > 0) {
              if (props.signedPalabra.palabraType == 'claimMint') {
                return `${mintsLeft} ${mintsLeft > 1 ? 'mints' : 'mint'} left`
              } else if (props.signedPalabra.palabraType == 'publicMint') {
                return `${mintsLeft} out of ${props.signedPalabra.quantityAllowed} mints left`
              }
            } else {
              if (props.signedPalabra.palabraType == 'claimMint') {
                if (props.collectionsWalletTokens == undefined) {
                  return 'error loading tokens, try again later'
                } else {
                  if (claimableTokens.value) {
                    return 'no mints left for this wallet'
                  } else {
                    return 'Loading, please wait'
                  }
                }
              } else {
                return 'no mints left'
              }
            }
          }
        }
      }

      const doPremiumMinting = async () => {
        dispatch('setLoading', true)
        if (allowToPremiumMint) {
          try {
            const params = {
              contractAddress:
                selectedProject.value?.configuration.contractAddress,
              walletAddress: walletAddress.value,
              palabra: props.signedPalabra,
            }
            if (props.signedPalabra.authorizedAddress == '0') {
              await palabrasContractService.premiumMint(params)
            } else {
              await palabrasContractService.premiumRequestedMint(params)
            }
            dispatch('setMintedQty', 1)
            dispatch('setMintingStep', 2)
          } catch (error) {
            if (error.code == 4001) {
              selectNotification(error)
            } else {
              const errorMessage =
                error.code == -32603 ? 'Error processing TX' : error.message
              dispatch('setTxError', errorMessage)
              dispatch('setMintingStep', 4)
            }
          }
        }
        dispatch('setLoading', false)
      }

      const doMinting = async () => {
        dispatch('setLoading', true)
        if (allowToMint()) {
          try {
            const params = {
              contractAddress:
                selectedProject.value?.configuration.contractAddress,
              walletAddress: walletAddress.value,
              palabra: props.signedPalabra,
              quantityToMint: quantityToMint.value,
              price: props.mintPrice * quantityToMint.value,
            }
            if (props.publicMinting) {
              await palabrasContractService.publicMint(params)
            } else {
              if (props.allowListMinting) {
                const allowListSignature = await getAllowListSignature(
                  walletAddress.value
                )
                params.signature = allowListSignature.response.signature
                await palabrasContractService.allowListMint(params)
              }
            }
            dispatch('setMintedQty', quantityToMint.value)
            dispatch('setMintingStep', 2)
          } catch (error) {
            if (error.code == 4001) {
              selectNotification(error)
            } else {
              const errorMessage =
                error.code == -32603 ? 'Error processing TX' : error.message
              dispatch('setTxError', errorMessage)
              dispatch('setMintingStep', 4)
            }
          }
        }
        dispatch('setLoading', false)
      }

      const doClaiming = async () => {
        dispatch('setLoading', true)
        if (allowToClaim()) {
          try {
            await loadWalletTokens()
            const params = {
              contractAddress:
                selectedProject.value?.configuration.contractAddress,
              walletAddress: walletAddress.value,
              palabra: props.signedPalabra,
              tokenId: claimableTokens.value.tokenId,
            }
            await palabrasContractService.claimMint(params)
            dispatch('setMintedQty', 1)
            dispatch('setMintingStep', 2)
          } catch (error) {
            if (error.code == 4001) {
              selectNotification(error)
            } else {
              const errorMessage =
                error.code == -32603 ? 'Error processing TX' : error.message
              dispatch('setTxError', errorMessage)
              dispatch('setMintingStep', 4)
            }
          }
        }
        dispatch('setLoading', false)
      }

      const itemCardClass = () => {
        if (props.signedPalabra.palabraType == 'premiumMint') {
          return 'gold-border palabras-card'
        } else {
          return 'palabras-card'
        }
      }

      const starTooltipContent = () => {
        let mssg = 'This palabra has utility for the following frases: '
        for (const frase of props.palabraFrases) {
          mssg += `<br/>${frase.phrase.toUpperCase()}`
        }
        return mssg
      }

      const claimTooltipContent = () => {
        return `For holders of ${props.signedPalabra.collectionName}`
      }

      const getAllowListSignature = async (walletAddress) => {
        let response
        let error
        try {
          response = await getPalabrasAllowListSignature(
            walletAddress,
            props.signedPalabra
          ).then((res) => res.data.response)
        } catch (e) {
          error = e
          console.log('Error getting palabra allowList signature', e)
        }
        return { response, error }
      }

      onBeforeMount(async () => {
        if (web3.value) {
          dispatch('setLoading', false)
          await loadContractData()
          if (props.signedPalabra.palabraType == 'claimMint') {
            await loadWalletTokens()
          }
          dispatch('setLoading', false)
        }
      })

      watch([props.collectionsWalletTokens], async () => {
        dispatch('setLoading', false)
        await loadContractData()
        if (props.signedPalabra.palabraType == 'claimMint') {
          await loadWalletTokens()
        }
        dispatch('setLoading', false)
      })

      return {
        premiumPriceToEth,
        quantityToMint,
        starTooltipContent,
        claimTooltipContent,
        allowToPremiumMint,
        allowToClaim,
        allowToMint,
        availableMints,
        msgToAvailableMint,
        doMinting,
        doPremiumMinting,
        doClaiming,
        itemCardClass,
      }
    },
  }
</script>

<style scoped>
  .subtitle {
    font-weight: bold;
    text-align: left;
  }

  .small-word {
    font-size: 10px;
  }

  .mint-button {
    float: right;
    min-width: 140px;
  }

  .palabras-card {
    min-width: 340px;
    height: 75px;
    text-align: left;
    margin: 6px 10px 6px 0px;
  }

  .mintBtnDiv {
    display: flex;
    float: right;
    justify-content: flex-end;
  }

  .gold-border {
    --borderWidth: 3px;
    border-radius: var(--borderWidth);
  }
  .gold-border:after {
    content: '';
    position: absolute;
    top: 3px;
    left: -3px;
    height: calc(100% - 6px);
    width: calc(100% - 4px);
    background: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
  .gold-background {
    background: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
    animation: animatedgradient 10s ease alternate infinite;
    background-size: 300% 300%;
  }
  .gold-text {
    font-weight: bolder;
    background-image: linear-gradient(
      to right,
      #462523 0,
      #cb9b51 22%,
      #f6e27a 45%,
      #f6f2c0 50%,
      #f6e27a 55%,
      #cb9b51 78%,
      #462523 100%
    );
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 300% 300%;
  }
  @keyframes animatedgradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  .palabra-badge {
    position: absolute;
    top: 0;
    margin-left: 5px;
    width: 10px;
  }

  .claim-palabra-badge {
    position: relative;
    margin-left: 5px;
    top: 2px;
    width: 15px;
  }
</style>
