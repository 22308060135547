<template>
  <div class="container">
    <MintingExperience projectSlug="palabras" />
    <div v-if="mintingStep < 1">
      <div v-if="mintedTokens.length > 0">
        <el-row style="margin: 10px 0px 10px">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="title">EXAMPLE MINTS</div>
          </el-col>
        </el-row>
        <el-row style="margin-bottom: 5px" :gutter="20">
          <el-col
            :xs="24"
            :sm="8"
            :md="8"
            :lg="8"
            :xl="8"
            v-for="(token, index) in mintedTokens"
            :key="index"
            style="margin: 0 0 1% 0"
            :class="index >= 1 ? 'hide-on-small' : ''"
          >
            <PalabrasMintTokenCard :tokenData="token" />
          </el-col>
        </el-row>
        <hr class="palabras-divider" />
      </div>
      <div v-if="!addressStateRef" class="palabras">
        <div class="connect-wallet-alert-div">
          <el-alert
            class="connect-wallet-alert"
            :closable="false"
            effect="dark"
            type="warning"
            show-icon
          >
            <h2>Please connect your wallet to use Palabras</h2>
          </el-alert>
        </div>
      </div>
      <div
        v-if="addressStateRef && !connectedToDesiredNetwork"
        class="palabras"
      >
        <div class="connect-wallet-alert-div">
          <el-alert
            class="connect-wallet-alert"
            :closable="false"
            effect="dark"
            type="warning"
            show-icon
          >
            <h2>
              Please connect your wallet to the
              {{ desiredNetworkName }} network.
            </h2>
          </el-alert>
        </div>
      </div>
      <div v-loading="loading" v-if="connectedToDesiredNetwork">
        <el-row class="title-row">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="title">MINTING ROUND {{ currentRound }}</div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            class="s1-header-btn"
          >
            <el-button-group class="palabras-buttons">
              <el-button type="primary" @click="browseGallery()">
                Browse Gallery
                <el-icon class="el-icon--right"><Grid /></el-icon>
              </el-button>
              <el-button
                type="primary"
                @click="scrollToPreviousRounds()"
                v-if="currentRound > 1"
              >
                Previous Rounds
                <el-icon class="el-icon--right"><ArrowDownBold /></el-icon>
              </el-button>
            </el-button-group>
          </el-col>
          <el-col :span="24" style="text-align: left">
            <el-tag
              v-if="isContractPaused"
              style="margin: 1% 0"
              effect="dark"
              size="large"
              round
            >
              Minting will start soon.
            </el-tag>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0px" :gutter="20">
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            class="palabras-col"
          >
            <el-row>
              <div class="subtitle">PUBLIC MINTS</div>
              <el-tooltip
                class="item"
                effect="dark"
                content="These are the palabras available for public minting in this round. Supplies for each palabra are individually assigned."
                placement="bottom"
                :show-after="100"
              >
                <el-button type="primary" :icon="QuestionFilled" circle />
              </el-tooltip>
            </el-row>
            <el-row>
              <el-tag
                v-if="publicMintsMessage.displayMessage"
                :type="publicMintsMessage.type"
                style="margin: 0 0 1% 0"
                effect="dark"
                size="large"
                round
              >
                {{ publicMintsMessage.message }}
              </el-tag>
            </el-row>
            <div
              v-for="signedPalabra in roundSignedPublicPalabras[0]"
              :key="signedPalabra.word"
            >
              <PalabrasListItem
                v-if="palabraFrases(signedPalabra)"
                :signedPalabra="signedPalabra"
                :currentRound="currentRound"
                :mintPrice="publicPrice"
                :publicMinting="publicMinting"
                :allowListMinting="allowListMinting"
                :isInAllowList="isInAllowList"
                :contractPaused="isContractPaused"
                :palabraFrases="palabraFrases(signedPalabra)"
              />
            </div>
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            class="palabras-col"
          >
            <el-row>
              <div class="subtitle">PREMIUM MINTS</div>
              <el-tooltip
                class="item"
                effect="dark"
                content="These are single edition, 1/1, premium palabras available to mint right now. Once minted, they will never be available to mint again, nor ever released as a public or community palabra (enforced by the smart contract)."
                placement="bottom"
                :show-after="100"
              >
                <el-button type="primary" :icon="QuestionFilled" circle />
              </el-tooltip>
            </el-row>
            <div
              v-for="signedPalabra in roundSignedPremiumPalabras[0]"
              :key="signedPalabra.word"
            >
              <PalabrasListItem
                v-if="authRequestedPalabra(signedPalabra.authorizedAddress)"
                :signedPalabra="signedPalabra"
                :currentRound="currentRound"
                :contractPaused="isContractPaused"
                :palabraFrases="palabraFrases(signedPalabra)"
              />
            </div>
            <!--div style="text-align: left; margin-top: 10px">
              <hr class="palabras-divider" style="margin: 5% 0" />
              <PalabrasEmailForm />
            </div--->
          </el-col>
          <el-col
            :xs="24"
            :sm="24"
            :md="24"
            :lg="8"
            :xl="8"
            class="palabras-col"
          >
            <el-row>
              <!--div class="subtitle">COMMUNITY MINTS</div>
              <el-tooltip
                class="item"
                effect="dark"
                content="These palabras are free to mint (gas fees apply) for token holders of featured communitites in this round. You can mint one palabra for each token you hold in the corresponding collection."
                placement="bottom"
                :show-after="100"
              >
                <el-button type="primary" :icon="QuestionFilled" circle />
              </el-tooltip-->
            </el-row>
            <div
              v-for="signedPalabra in roundSignedClaimPalabras[0]"
              :key="signedPalabra.word"
            >
              <PalabrasListItem
                :signedPalabra="signedPalabra"
                :currentRound="currentRound"
                :contractPaused="isContractPaused"
                :palabraFrases="palabraFrases(signedPalabra)"
                :collectionsWalletTokens="collectionsWalletTokens"
              />
            </div>
          </el-col>
        </el-row>
        <div v-if="currentRound > 1" id="previous-rounds">
          <div v-for="index in currentRound - 1" :key="index">
            <hr class="palabras-divider" />
            <el-row class="title-row">
              <el-col :span="24">
                <div class="title">ROUND {{ currentRound - index }}</div>
              </el-col>
            </el-row>
            <el-row style="margin: 10px 0px">
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="8"
                :xl="8"
                class="palabras-col"
              >
                <div class="subtitle">PUBLIC MINTS</div>
                <div
                  v-for="signedPalabra in roundSignedPublicPalabras[index]"
                  :key="signedPalabra.word"
                >
                  <PalabrasListItem
                    :signedPalabra="signedPalabra"
                    :currentRound="currentRound"
                    :mintPrice="publicPrice"
                    :palabraFrases="palabraFrases(signedPalabra)"
                  />
                </div>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="8"
                :xl="8"
                class="palabras-col"
              >
                <div class="subtitle">PREMIUM MINTS</div>
                <div
                  v-for="signedPalabra in roundSignedPremiumPalabras[index]"
                  :key="signedPalabra.word"
                >
                  <PalabrasListItem
                    :signedPalabra="signedPalabra"
                    :currentRound="currentRound"
                    :palabraFrases="palabraFrases(signedPalabra)"
                  />
                </div>
              </el-col>
              <el-col
                :xs="24"
                :sm="24"
                :md="24"
                :lg="8"
                :xl="8"
                class="palabras-col"
              >
                <div class="subtitle">COMMUNITY MINTS</div>
                <div
                  v-for="signedPalabra in roundSignedClaimPalabras[index]"
                  :key="signedPalabra.word"
                >
                  <PalabrasListItem
                    :signedPalabra="signedPalabra"
                    :currentRound="currentRound"
                    :palabraFrases="palabraFrases(signedPalabra)"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Grid, ArrowDownBold, QuestionFilled } from '@element-plus/icons'
  import { useStore } from 'vuex'
  import { computed, ref, onBeforeMount, watch, onBeforeUnmount } from 'vue'

  import PalabrasListItem from '@/components/projects/palabras/palabras-list-item/'
  import PalabrasMintTokenCard from '@/components/projects/palabras/palabras-mint-token-card/'
  //import PalabrasEmailForm from '@/components/projects/palabras/palabras-email-form/'

  import { getRoundSignedPalabras } from '@/services/PalabrasService'
  import { getAllFrases } from '@/services/FrasesService'
  import {
    getProjectTokens,
    retrieveAssetsFromOpensea,
  } from '@/services/TokenService'
  import PalabrasContractService from '@/services/PalabrasContractService'
  import { selectProjectContract } from '@/services/ProjectService'
  import { getPalabrasAllowListAddress } from '@/services/PalabrasAllowListService'
  import { useRouter } from 'vue-router'
  import MintingExperience from '@/components/minting-experience/'
  import { NETWORK_IDS, NETWORK_NAMES } from '@/constants/walletConstants'

  export default {
    name: 'PalabrasList',
    components: {
      PalabrasMintTokenCard,
      PalabrasListItem,
      //PalabrasEmailForm,
      Grid,
      ArrowDownBold,
      MintingExperience,
    },
    setup() {
      const store = useStore()
      const {
        dispatch,
        state: { contractState, user, mintingExperience },
      } = store

      const router = useRouter()
      const contract = computed(() => contractState.contract)
      const walletAddress = computed(() => user.walletAddress)
      const networkId = computed(() => user.networkId)
      const connectedToDesiredNetwork = ref(false)
      const mintingStep = computed(() => mintingExperience.mintingStep)
      const loading = ref(false)
      const publicPrice = ref(0)
      const currentRound = ref(0)
      const isContractPaused = ref(false)
      const allowListMinting = ref(false)
      const publicMinting = ref(false)
      const isInAllowList = ref(false)
      const publicMintsMessage = ref({})
      const roundSignedPremiumPalabras = ref([])
      const roundSignedPublicPalabras = ref([])
      const roundSignedClaimPalabras = ref([])
      const web3 = computed(() => contractState.web3)
      const mintedTokens = ref([])
      const frasesData = ref([])
      const addressStateRef = ref(walletAddress)
      const desiredNetworkName = ref('')
      let palabrasContractService

      const collectionsWalletTokens = ref([])

      const loadContractData = async () => {
        palabrasContractService = new PalabrasContractService(
          web3.value,
          contract.value,
          true,
          store
        )
        isContractPaused.value = await palabrasContractService.paused()
        currentRound.value = await palabrasContractService.getCurrentRound()
        publicPrice.value = await palabrasContractService.publicPrice(
          web3.value
        )

        allowListMinting.value =
          await palabrasContractService.allowListMinting()
        publicMinting.value = await palabrasContractService.publicMinting()

        if (publicMinting.value) {
          publicMintsMessage.value = {
            displayMessage: false,
          }
        } else {
          if (allowListMinting.value) {
            isInAllowList.value = await getPalabrasAllowListAddress(
              walletAddress.value.toLowerCase(),
              currentRound.value
            ).then((res) => {
              return res.response
            })
            if (isInAllowList.value) {
              publicMintsMessage.value = {
                displayMessage: !isContractPaused.value,
                type: 'success',
                message: "You're on the allow list.",
              }
            } else {
              publicMintsMessage.value = {
                displayMessage: !isContractPaused.value,
                type: 'danger',
                message:
                  "You're not on the allow list. Public mint starting soon.",
              }
            }
          } else {
            if (!allowListMinting.value) {
              publicMintsMessage.value = {
                displayMessage: !isContractPaused.value,
                type: '',
                message: 'Public or allow list minting will resume soon.',
              }
            }
          }
        }
      }

      const loadSignedPalabras = async () => {
        frasesData.value = await getAllFrases()
        roundSignedPublicPalabras.value = []
        roundSignedPremiumPalabras.value = []
        roundSignedClaimPalabras.value = []

        for (let round = currentRound.value; round >= 1; round--) {
          const roundPublicPalabras = await getRoundSignedPalabras(
            round,
            'publicMint'
          )
          const roundClaimPalabras = await getRoundSignedPalabras(
            round,
            'claimMint'
          )
          let roundPremiumPalabras = await getRoundSignedPalabras(
            round,
            'premiumMint'
          )
          if (round == currentRound.value) {
            const notMintedPremiumWords = await getRoundSignedPalabras(
              '0',
              'premiumMint'
            )
            roundPremiumPalabras =
              notMintedPremiumWords.concat(roundPremiumPalabras)
          }

          roundSignedPublicPalabras.value[currentRound.value - round] =
            roundPublicPalabras
          roundSignedClaimPalabras.value[currentRound.value - round] =
            roundClaimPalabras
          roundSignedPremiumPalabras.value[currentRound.value - round] =
            roundPremiumPalabras
        }
      }

      const scrollToPreviousRounds = () => {
        document.querySelector('#previous-rounds').scrollIntoView({
          behavior: 'smooth',
        })
      }

      const browseGallery = () => {
        dispatch('resetTokenAttrs')
        dispatch('resetTokensData')
        router.push({ path: '/tokens/palabras', query: {} })
      }

      const authRequestedPalabra = (authorizedAddress) => {
        return (
          authorizedAddress == '0' ||
          authorizedAddress.toLowerCase() == walletAddress.value.toLowerCase()
        )
      }

      const palabraFrases = (signedPalabra) => {
        return frasesData.value?.filter((frase) =>
          frase.palabras
            .map((p) => {
              return p.toUpperCase()
            })
            .includes(signedPalabra.word.toUpperCase())
        )
      }

      const getWalletTokens = async () => {
        const roundClaimPalabras = await getRoundSignedPalabras(
          currentRound.value,
          'claimMint'
        )
        const claimableCollections = roundClaimPalabras.map(
          (p) => p.collectionAddress
        )
        if (claimableCollections.length > 0) {
          collectionsWalletTokens.value = await retrieveAssetsFromOpensea(
            claimableCollections,
            walletAddress.value
          ).then((res) => res.response)
        }
      }

      onBeforeMount(async () => {
        dispatch('setMintingStep', 0)
        const tokenIndexes = []
        const palabrasTokens = await getProjectTokens(
          'palabras',
          'tokenID',
          'asc'
        ).then((res) => res.response)
        const tokensWithImage = palabrasTokens.filter((t) => t.image)
        if (tokensWithImage.length > 3) {
          for (let i = 0; i < 3; i++) {
            let n = Math.floor(Math.random() * (tokensWithImage.length - 1) + 1)
            if (tokenIndexes.includes(n)) {
              i = i - 1
            } else {
              tokenIndexes.push(n)
              mintedTokens.value.push(tokensWithImage[n])
            }
          }
        }
        if (web3.value) {
          desiredNetworkName.value =
            NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
          loading.value = true
          await selectProjectContract('palabras', web3.value, dispatch)
          await loadContractData()
          await getWalletTokens()
          await loadSignedPalabras()
          connectedToDesiredNetwork.value =
            networkId.value &&
            NETWORK_IDS[process.env.NODE_ENV] == networkId.value
          loading.value = false
        }
      })

      onBeforeUnmount(() => {
        dispatch('setMintingStep', 0)
      })

      watch([web3, walletAddress, networkId], async () => {
        connectedToDesiredNetwork.value =
          networkId.value &&
          NETWORK_IDS[process.env.NODE_ENV] == networkId.value
        desiredNetworkName.value =
          NETWORK_NAMES[NETWORK_IDS[process.env.NODE_ENV]]
        loading.value = true
        await selectProjectContract('palabras', web3.value, dispatch)
        await loadContractData()
        await getWalletTokens()
        await loadSignedPalabras()
        loading.value = false
      })

      return {
        loading,
        addressStateRef,
        desiredNetworkName,
        QuestionFilled,
        roundSignedPublicPalabras,
        roundSignedPremiumPalabras,
        roundSignedClaimPalabras,
        publicPrice,
        currentRound,
        allowListMinting,
        publicMinting,
        isInAllowList,
        publicMintsMessage,
        isContractPaused,
        palabraFrases,
        scrollToPreviousRounds,
        browseGallery,
        authRequestedPalabra,
        connectedToDesiredNetwork,
        mintedTokens,
        mintingStep,
        collectionsWalletTokens,
      }
    },
  }
</script>

<style scoped>
  .title {
    font-weight: bolder;
    font-size: 30px;
    text-align: left;
    white-space: nowrap;
  }
  .title-row {
    margin: 3% 0% 2% 0%;
  }
  .subtitle {
    font-weight: bolder;
    font-size: 25px;
    text-align: left;
    white-space: nowrap;
    margin-bottom: 15px;
  }

  .subtitle {
    font-weight: bold;
    margin-right: 20px;
  }

  .left {
    width: 100%;
    float: left;
    height: 50px;
    text-align: justify;
  }

  .palabras-col {
    margin: 10px 0px;
    text-align: left;
  }

  .container {
    margin: 2% 5%;
  }

  .palabras-description {
    text-align: left;
    font-size: 14px;
    padding: 20px 40px 20px 20px;
    border-radius: 4px;
    border-left: 5px solid var(--el-color-primary);
    background-color: rgb(235 245 254);
    margin-right: 30px;
  }

  .palabras-request {
    text-align: left;
    font-size: 16px;
    padding: 20px 40px 20px 20px;
    border-radius: 4px;
    border-left: 5px solid var(--el-color-primary);
    background-color: rgb(235 245 254);
    margin-right: 30px;
  }
  .s1-header-btn {
    text-align: right;
    margin: 0%;
  }
  .hide-on-small {
    display: block;
  }
  .palabras-buttons {
    padding-right: 1%;
  }

  .palabras-divider {
    margin: 1% 0% 1% 0%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(
      to right,
      #bf953f,
      #fcf6ba,
      #b38728,
      #fbf5b7,
      #aa771c
    );
  }
  .connect-wallet-alert {
    width: fit-content !important;
    margin: 30px auto 0 auto !important;
  }

  @media screen and (max-width: 767px) {
    .s1-header-btn {
      text-align: right;
      margin: 2% 0%;
    }

    .hide-on-small {
      display: none;
    }
  }
</style>
